(function($, window, document) { // jQuery wrapper

    // Pagination Functions
    paginationFunctions = {

        container: '',

        // Setups all block functions (each function needs to be called within this function)
        setup : function() {
            // Get the containers
            paginationFunctions.container = $('.j-ajax');

            // If there are containers, run the function.
            if( paginationFunctions.container.length > 0 ){

                // Setup the Banner block JS
                paginationFunctions.ajaxLoadMore();

                // Setup the Change Catergory
                paginationFunctions.changeCategory();
            }
        },
      
        // Setup the ajax LoadMore functionality
        ajaxLoadMore: function(){
            // When load more button is clicked get the page, add the posts and replace the pagination button.
            paginationFunctions.container.on('click', '.j-ajax--pagination a', function(e){
                e.preventDefault();
                window._fetch.fetch($(this).attr('href'), function(newPage){
                    $('.j-ajax--posts').append(newPage.find('.j-ajax--posts').html()); // add new posts
                    $('.j-ajax--category').html(newPage.find('.j-ajax--category').html()); // replace filters
                    $('.j-ajax--pagination').html(newPage.find('.j-ajax--pagination').html()); // replace pagination
                });
            });
        },
        // Setup the ajax category functionality
        changeCategory: function(){
            paginationFunctions.container.on('change', '.j-ajax--select', function(e){
               e.preventDefault();
               var url = $(this).val();
                window._fetch.fetch(url, function(newPage){
                    $('.j-ajax--posts').html(newPage.find('.j-ajax--posts').html()); // add new posts
                    $('.j-ajax--category').html(newPage.find('.j-ajax--category').html()); // replace filters
                    $('.j-ajax--pagination').html(newPage.find('.j-ajax--pagination').html()); // replace pagination
                    
                    // window._fetch.setURL( url );
                });
            });
        },

    };
    window.PaginationFunctions = paginationFunctions;
}(window.jQuery, window, document)); // End of jQuery wrapper

