(function($, window, document) { // jQuery wrapper

    // Page Functions

    // Call setup with window.BlockFunctions.setup();
    // call individual blocks with window.BlockFunctions.blocName(); ex window.BlockFunctions.banner();
    blockFunctions = {
        // Setups all block functions (each function needs to be called within this function)
        setup : function() {
            // Setup the Banner block JS
            blockFunctions.banner();

            // Setup the Content block JS
            blockFunctions.content();

            // Setup the Callouts block JS
            blockFunctions.callouts();

            // Setup the Call To Action block JS
            blockFunctions.callToAction();

            // Setup the Post List block JS
            blockFunctions.postList();
            
            // Setup the Gallery block JS
            blockFunctions.gallery();
        },
      
        // Setup the Banner block JS
        banner: function(){
            // Banner Functionality
        },
        // Setup the Content block JS
        content: function(){
            // Content Functionality
        },
        // Setup the Callouts block JS
        callouts: function(){
            // Callouts Functionality
        },
        // Setup the Call To Action block JS
        callToAction: function(){
            // Call To Action  Functionality
        },
        // Setup the Post List block JS
        postList: function(){
            // Post List  Functionality
        },
        // Setup the Gallery block JS
        gallery: function(){
            // Gallery Functionality
            $('.j-gallery').featherlightGallery({
                previousIcon: '&#9664;',     /* Code that is used as previous icon */
                nextIcon: '&#9654;',         /* Code that is used as next icon */
                galleryFadeIn: 100,          /* fadeIn speed when slide is loaded */
                galleryFadeOut: 300          /* fadeOut speed before slide is loaded */
            });
        }

    };
    window.BlockFunctions = blockFunctions;
}(window.jQuery, window, document)); // End of jQuery wrapper

